<template>
  <div class="container">
    <div>
      <slot class="mb-1" name="calendar-title"></slot>
      <b-form-datepicker
        selected-variant="pink-light"
        no-flip
        today-variant="pink-light"
        locale="sk"
        nav-button-variant="blue-dark"
        v-model="date"
        v-bind="label || {}"
        :min="min"
        :hide-header="true"
        class="calendar"
      >
        <template v-slot:button-content>
          <svg-calendar-icon />
        </template>
      </b-form-datepicker>
    </div>
    <div>
      <slot name="clock-title"></slot>
      <b-form-group ref="modal-clock-group" class="m-0">
        <b-form-timepicker
          ref="modal-clock"
          locale="sk"
          v-model="time"
          hide-header
          no-close-button
          label-no-time-selected="Vyberte čas"
        >
          <template v-slot:button-content>
            <svg-clock-icon />
          </template>
        </b-form-timepicker>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  components: {
    "svg-calendar-icon": () => import("/assets/icons/calendar.svg?inline"),
    "svg-clock-icon": () => import("/assets/icons/clock.svg?inline"),
  },

  props: {
    preselectedCurrentDate: {
      type: Boolean,
      required: false,
    },
  },

  mounted() {
    if (this.preselectedCurrentDate) {
      const now = new Date();
      this.date = moment(now).tz("Europe/Bratislava").format("YYYY-MM-DD");
      this.time = moment(now).tz("Europe/Bratislava").format("HH:mm:ss");
    }
  },

  watch: {
    date: {
      immediate: true,
      handler(v) {
        this.$emit("date", v);
      },
    },
    time: {
      immediate: true,
      handler(v) {
        this.$emit("time", v);
      },
    },
  },

  data() {
    const now = new Date();
    const todayDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    );
    return {
      min: todayDate,
      date: "",
      time: "08:00:00",
      label: {
        labelPrevDecade: "Minulé desaťročie",
        labelPrevYear: "Minulý rok",
        labelPrevMonth: "Minulý mesiac",
        labelCurrentMonth: "Tento mesiac",
        labelNextMonth: "Ďalší mesiac",
        labelNextYear: "Ďalší rok",
        labelNextDecade: "Ďalšie desaťročie",
        labelToday: "Dnes",
        labelSelected: "Vybraný dátum",
        labelNoDateSelected: "Vyberte dátum",
        labelCalendar: "Kalendár",
        labelNav: "Navigácia v kalendári",
        labelHelp: "",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  @media only screen and (min-width: 575px) {
    flex-direction: row;
    gap: initial;
  }

  & > div {
    border-bottom: 1px solid var(--a-color-blue-border);
    padding: 0 1rem;
  }
}

svg {
  cursor: pointer;
}

span {
  color: #2b2e4a;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
}

h4 {
  font-family: Boing !important;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #2b2e4a;
}

.calendar,
::v-deep .custom-select,
::v-deep .b-form-timepicker {
  border: none;
}

::v-deep .btn-outline-blue-dark:hover svg {
  color: white !important;
}

::v-deep .btn {
  padding: 0;
}

::v-deep .text-dark,
::v-deep .text-truncate,
::v-deep .text-center {
  color: #2b2e4a !important;
}

::v-deep .text-muted {
  color: rgba(156, 161, 174, 0.4) !important;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px !important;
  line-height: 27px !important;
}

::v-deep .modal-header {
  border-bottom: 0 none;
}

::v-deep .modal-content {
  top: 150px;
}

::v-deep .modal-body {
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    margin-top: auto !important;
    margin-bottom: auto !important;
    align-items: center;

    color: #2b2e4a;
  }
  .modal-box {
    align-items: center;
    width: 569px;
    height: 94px;
    left: 617px;
    top: 847px;

    background: #fff5d1;
    border: 1px solid #e6d8a7;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .modal-box-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;

    letter-spacing: -0.05em;

    color: #cab979;
  }
}
input {
  border: none;
  width: 100%;
  color: #2b2e4a;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  &::placeholder {
    color: rgba(156, 161, 174, 0.4) !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
  }
  &:focus {
    outline: 0;
  }
  &:read-only {
    background-color: transparent;
  }
}

::v-deep .modal-content {
  border-style: solid;
  border-width: 1px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-image: linear-gradient(90.01deg, #0064ff 60.75%, #fb2564 79.89%) 0 0
    100% 0/0 0 4.53px 0 stretch;
}

::v-deep .modal-footer {
  border-top: 0 none !important;
  align-items: left;
}

::v-deep button {
  margin: auto;
}
</style>
